import * as React from "react";
import { Heading, Box, Badge } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Tile = ({ tile }) => {
  return (
    <Box
      flexShrink={0}
      w={{ lg: "360px" }}
      display={{ base: "block", lg: "block" }}
      textAlign="center"
      p={4}
      borderWidth="1px"
      borderRadius="md"
      mb={0}
      mt={0}
      mr={2}
      ml={2}
    >
      <a href={tile.slug}>
        <Box mb={2} mt={2}>

          <GatsbyImage image={getImage(tile.slides[0].image)} alt="" backgroundColor='black' style={{ maxHeight: '200px' }}
                       imgStyle={{ objectFit: 'contain' }}/>
        </Box>
        {tile.slides['0'].category && (
            <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="red">
              {tile.slides['0'].category}
            </Badge>
        )}
        {tile.title && (
          <Heading mt={2} as="h4" size={"md"}>
            {tile.title}
          </Heading>

        )}
      </a>
    </Box>
  );
};

export default Tile;
