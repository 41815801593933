import * as React from "react";
import { graphql } from "gatsby";
import Tile from "../components/tile";
import Footer from "../components/footer";
import { Box, Container, SimpleGrid, Stack,} from "@chakra-ui/react";

const IndexPage = ({ data }) => {

  const siteData = data.site.siteMetadata;
  const siteLogo = `/logos/${siteData.logo}`;
  const tileData = data.allSlideshowsJson.nodes;

  return (
      <Box p={4}>
          <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <Box mx="auto">
                <img src={siteLogo} alt={siteData.title} width={250} />
              </Box>
          </Stack>
          <Container maxW={'6xl'} mt={120}>
              <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3  }}
                          spacingX={5}
                          spacingY={5}>
                  {tileData.map((tile, i) => {
                      return <Tile key={i} tile={tile} />;
                  })}
              </SimpleGrid>
          </Container>
          <Footer site={siteData}/>
      </Box>
  );
};

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
        siteUrl
        title
        logo
      }
    }
    allSlideshowsJson {
      nodes {
        slug
        title
        slides {
          category
          imageTitle
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
